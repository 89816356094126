export default {
  carrierCodes: {
    clickAndCollect: {
      pharmacyCollectCarrierCode: 'drmaxclickandcollectshipping',
      pickupPlaceCarrierCode: 'drmaxpickupplace',
      pharmacyHookCode: 'pharmacy',
    },
    limitedByZipCode: {},
    timeSlotDelivery: {},
  },
}
