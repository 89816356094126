export default {
  features: {
    alternateAvailabilityText: true,
    filterableAttributesEnabled: true,
    nsfSlotEnabled: true,
    priceInfoMayhem: true,
    robotsFromConfigEnabled: true,
    watchdogProductPriceLowerBoundValue: 1.0,
    reviewsDisclaimerEnabled: false,
  },
}
