import config from '@nsf/catalog/config/index.mjs'

export default {
  catalog: {
    categories: {
      freeGiftCategory: 2587,
      onePlusOneCategory: 2583,
      rootCategory: 1269,
      saleCategory: 2691,
      specialOffersCategory: 2589,
      threeForTwoCategory: 2585,
    },
    depositFeeEnabled: true,
    discounts: {
      maxBrands: 9001,
      maxCategories: 9001,
    },
    filters: {
      ...config,
      attributes: {
        age: [
          'drmax_age_from',
          'drmax_age_to',
        ],
        special: [
          'drmax_brand',
          'drmax_product_line',
        ],
        rangeAttributes: [
          'drmax_price',
          'final_price',
          'drmax_age_from',
          'drmax_age_to',
          'drmax_age_range',
        ],
        priorityAttributes: [
          'drmax_private_brand',
          'drmax_saleoff_action_is_special_price_active',
        ],
      },
      resetList: [
        'filter',
        'sort',
        'page',
        'range',
        'search',
      ],
    },
    pim: {
      temporaryUnavailable: 'Temporary unavailable',
    },
    showSimpleTemporaryUnavailable: true,
    suklWidgetEnabled: false,
  },
}
