/* eslint-disable sort-keys */
export default {
  features: {
    suklWidgetEnabled: false,

    // Cart
    salesRulesEnabled: true,
    fillCartFromUrlParamsEnabled: true,
    cartItemsMaxQtyNotificationEnabled: false,
    cartItemsStockEnabled: true,
    cartItemsAllowBackOrdersEnabled: false,
    cartItemsRowOriginalPriceEnabled: true,
    salesRuleRemovalEnabled: false,
    totalExcludingVatEnabled: false,
    couponsEnabled: true,
    competitionService: {
      competitionImagePath: '',
      competitionWidgetEnabled: false,
    },
    giftCardsEnabled: false,
    freeShippingBoxEnabled: true,
    freeShippingDescriptionEnabled: true,
    freeShippingViaSalesRulesEnabled: true,
    favoriteMethodsInfoBoxEnabled: true,
    boundProductsEnabled: true,
    pickupIdFetchingEnabled: false,
    setCartIdFromUrlParamsEnabled: true,

    // Shipping and payment
    orderTypeEnabled: true,
    otcOrderPreferenceEnabled: false,
    reservationsWithRxProductsEnabled: false,
    deliveryFeEnabled: false,
    paymentFeeEnabled: true,
    vouchersModalEnabled: false,
    removeCartItemsModalEnabled: false,
    reservationInfoBoxEnabled: true,
    reservationsIgnoreStockStatusEnabled: false,
    reservationCheckOpeningHoursEnabled: true,
    pickupPlaceCoordinatesEnabled: false,
    pickupPlaceAddressEnabled: false,
    pickupPlaceOpeningHoursEnabled: false,
    pickupPlaceExtensionAttributesEnabled: false,
    rxOnlyInReservationsEnabled: false,
    useCommonMap: false, // don't merge true to master, for dev only
    useDtcForBoxes: false,
    usePickupSelectV2: false,
    isDrmaxBoxUpdatedFromPickupPlaces: true,
    simpleDeliveryTimeEnabled: true,
    withPickupTimes: true,

    // Address forms
    fillFormsFromLogin: true,
    persistFormsInLocalStorage: true,
    phoneSlicingEnabled: false,
    streetNumberToMagentoEnabled: false,
    shippingAddressNameEnabled: false,
    countrySelectEnabled: false,
    countrySelectChoiceEnabled: false,
    billingCountryInputDisabled: true,
    companyDataPrefillEnabled: true,
    privateInvoiceEnabled: false,
    crnAndVatIdValidationOnlyForDomesticOrdersEnabled: false,
    billingAddressSubheadingEnabled: false,
    optionalBillingInfoForCACOrderEnabled: true,
    optionalBillingInfoForCACReservationEnabled: true,
    customerNotesEnabled: true,
    regionBeforeCityEnabled: true,

    // Address validation
    addressValidationEnabled: true,

    // Agreements
    cartAgreementsEnabled: true,
    emailSmsAgreementEnabled: true,
    heurekaAgreementEnabled: false,
    personalDataAgreementEnabled: false,
    priceComparisonAgreementEnabled: false,
    staffinoAgreementEnabled: false,
    agreeToPayNoteEnabled: false,

    // Placing orders
    drmaxGatewayMethodEnabled: false,
    gtmOrderInfoGqlEnabled: true,
    placeOrderDeliveryCalculationEnabled: false,
    drmaxCjEventEnabled: false,
    extraDataInPlaceOrderEnabled: true,
    hideDefaultCountryInPreview: true,

    // Thank you pages
    payUSuccessCheckingEnabled: false,
    extraOfferEnabled: false,
    sponsoredProductsLabelEnabled: false,
    dataLayerEventForCeneoEnabled: false,
    nsfSlotEnabled: true,
    bankTransferDetailsDisplayEnabled: false,
  },
}
