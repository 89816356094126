export default {
  footer: {
    footerTopLogos: [],
    emailSubscription: true,
    paymentsImages: [
      {
        src: '/images/logo-grms.png',
        alt: 'logo MS',
        link: 'https://ms.ro/ro/',
        width: 125,
        height: 125,
      },
      {
        src: '/images/sol_sal.png',
        alt: 'SAL',
        link: 'https://anpc.ro/ce-este-sal/',
        width: 137,
        height: 75,
      },
      {
        src: '/images/pci_logo.png',
        alt: 'Payment Card Industry Data',
        width: 90,
        height: 75,
      },
      {
        src: '/images/visa_mc.png',
        alt: 'Payment Options',
        width: 51,
        height: 68,
      },
    ],
    suklImages: false,
    footerTopAbsoluteImages: [],
    footerTopAbsoluteImagesHeight: 90,
    feedatyWidget: false,
    feedatyMerchantId: '',
    useFooterCertificateBlock: true,
    footerCertificateText: 'DR.MAX S.R.L., CUI: 9378655, J23/673/2007, sediu Mogoșoaia, str. Aeroportului, nr. 53, Ilfov, punct de lucru: Mogoșoaia, str. Buiacului, nr. 2, Zona de Depozitare Suplimentară și Zona de Mezanin Suplimentară, Ilfov, autorizația MS <a href="https://cms-cdn.drmax.zone/media/ro/files/pagebuilder/pdf/buiacului---drogherie.pdf" target="_blank">3059/NT3480/04.06.2020</a>',
    footerCertificateTextClass: 'alert alert-info',
    footerCertificateImages: [
      {
        src: '/images/logo-ms.png',
        alt: 'SUKL VET',
        link: 'https://ms.ro/ro/informatii-de-interes-public/pacienti/farmacii-%C8%99i-drogherii/',
        width: 165,
        height: 138,
      },
    ],
    flags: true,
    mobileApplications: false,
  },
}
