const pickupInstructions = []

export default {
  orderLabel: 'Comanda la locker',
  reservationLabel: 'Rezervare in farmacie',
  rxReservationLabel: 'Rezervarea medicamentelor cu reteta la farmacie',
  unavailablePickupLabel: 'Nu se poate comanda in aceasta locatie',
  selectedPlaceLabel: 'Locatie selectata',
  confirmButtonLabel: 'Selecteaza locatia',
  getTitle() {
    return this.title
  },
  getSubtitle() {
    return [this.location.street, this.location.city].filter(Boolean).join(', ')
  },
  getInstructions() {
    return {
      place: this.location.description
        ? [
            {
              icon: 'info',
              fragments: [{ text: this.location.description }],
            },
          ]
        : [],
      pickup: pickupInstructions,
    }
  },
  pins: {
    enabled: 'pin-boxes-enabled.svg',
    disabled: 'pin-boxes-disabled.svg',
    selected: 'pin-boxes-selected.svg',
    loading: 'pin-loading.svg',
  },
  clusterFillColor: '#3C951F',
  icon: '/img/delivery/box.svg',
}
