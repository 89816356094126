const pickupInstructions = [
  {
    icon: 'shipping',
    fragments: [
      { text: 'Livrare catre' },
      {
        text: 'punctul de ridicare selectat.',
        isBold: true,
      },
    ],
  },
  {
    icon: 'sms',
    fragments: [
      { text: 'Veti primi notificare' },
      {
        text: 'prin e-mail si SMS in momentul in care comanda poate fi ridicata.',
        isBold: true,
      },
    ],
  },
  {
    icon: 'calendar',
    fragments: [
      { text: 'Puteti ridica comanda' },
      {
        text: 'in decurs de 48 de ore',
        isBold: true,
      },
      { text: 'din momentul in care aceasta a ajuns la punctul de ridicare selectat.' },
    ],
  },
]

export default {
  orderLabel: 'Comanda la locker',
  unavailablePickupLabel: 'Nu se poate comanda in aceasta locatie',
  selectedPlaceLabel: 'Locatia selectata',
  confirmButtonLabel: 'Alege punctul de ridicare',
  getTitle() {
    return this.title
  },
  getSubtitle() {
    return [this.location.street, this.location.city].filter(Boolean).join(', ')
  },
  getInstructions() {
    return {
      place: this.location.description
        ? [
            {
              icon: 'info',
              fragments: [{ text: this.location.description }],
            },
          ]
        : [],
      pickup: pickupInstructions,
    }
  },
  pins: {
    enabled: 'pin-easybox-enabled.svg',
    disabled: 'pin-easybox-disabled.svg',
    selected: 'pin-easybox-selected.svg',
    loading: 'pin-loading.svg',
  },
  clusterFillColor: '#BA1B02',
  icon: '/img/delivery/box.svg',
}
