import { ORDER_TYPE } from '../../utils/Utils.js'

const orderPickupInstructions = [
  {
    icon: 'shipping',
    fragments: [
      { text: 'Livrare in farmacie in' },
      {
        text: '48 de ore',
        isBold: true,
      },
    ],
  },
  {
    icon: 'sms',
    fragments: [
      {
        text: 'Veti primi notificare prin e-mail si SMS in momentul in care comanda poate fi ridicata.',
        isBold: true,
      },
    ],
  },
  {
    icon: 'calendar',
    fragments: [
      { text: 'Puteti ridica comanda' },
      {
        text: 'in maximum 5 zile din momentul in care a ajuns in farmacie.',
        isBold: true,
      },
    ],
  },
]

const reservationPickupInstructions = [
  {
    icon: 'pharmacy',
    fragments: [
      { text: 'Ridicare din' },
      {
        text: 'farmacie',
        isBold: true,
      },
    ],
  },
  {
    icon: 'sms',
    fragments: [
      {
        text: 'Imediat ce rezervarea dumneavoastra va fi pregatita pentru ridicare, va vom contacta prin SMS sau e-mail.',
        isBold: true,
      },
    ],
  },
  {
    icon: 'calendar',
    fragments: [
      { text: 'Puteti ridica rezervarea' },
      {
        text: 'in decurs de 5 zile din momentul in care aceasta a fost confirmata de catre farmacie.',
        isBold: true,
      },
    ],
  },
]

export default {
  orderLabel: 'Comanda eshop',
  reservationLabel: 'Rezervare in farmacie',
  rxReservationLabel: 'Rezervarea medicamentelor cu reteta la farmacie',
  unavailablePickupLabel: 'Ridicarea din farmacie nu este disponibila',
  selectedPlaceLabel: 'Farmacia selectata',
  confirmButtonLabel: 'Alege farmacia',
  inStockLabel: 'In stoc',
  onOrderLabel: 'Pentru comanda',
  rxReservationButtonLabel: 'Rezerva',
  rxOrderButtonLabel: 'Rezervare pentru comanda',
  getTitle() {
    return `Dr. Max Farmacie, ${this.title}`
  },
  getSubtitle() {
    return [this.location.street, this.location.city].filter(Boolean).join(', ')
  },
  getInstructions(orderType) {
    return {
      place: this.location.description
        ? [
            {
              icon: 'info',
              fragments: [{ text: this.location.description }],
            },
          ]
        : [],
      pickup: (() => {
        switch (orderType) {
          case ORDER_TYPE.ORDER:
            return orderPickupInstructions
          case ORDER_TYPE.RESERVATION:
            return reservationPickupInstructions
          default:
            break
        }

        return []
      })(),
    }
  },
  pins: {
    enabled: 'pin-pharmacy-enabled.svg',
    disabled: 'pin-pharmacy-disabled.svg',
    selected: 'pin-pharmacy-selected.svg',
    loading: 'pin-loading.svg',
  },
  clusterFillColor: '#3C951F',
  icon: '/img/delivery/cac.svg',
}
